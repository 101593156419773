body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;

  p {margin-bottom: 5px;}
}

.main-content {
  background-color: white;
}

.home-buttons {
  .label {
    color: black;
    font-size: 1.2em;
  }
}

.standard-button {
  border-radius: 5px;
  span {
    font-weight: bold;
  }
}
.button-with-icon {
  text-align: left;
  border-radius: 5px;
  height: 45px;
  .anticon {
    float: left;
    margin-left: 20px !important;
  }
  span:not(.anticon) {
    font-weight: bold;
  }
}

.login-form {
  padding-top: 40px;
  padding-bottom: 20px;
  max-width: 250px;
  margin: 0 auto;
  img.logo {
    height: 100px;
    width: auto;
  }
  .dividers{
    margin: 10px 0px;
  }
  button {
    line-height: 30px;
    .google, .facebook {
       font-size: 30px;
    }
  }
  .floating-input-container .floating__input:focus-visible {border: 0px}
}

.button-standard {
  display: block;
  width: 100%;
  background-color: blue;
  color: white;
  border: 0px;
  font-size: 1.2em;
  padding: 5px 0px;
  border-radius: 5px;
}

.home-offers {
  padding-right: 8px;
  padding-left: 8px;
  h2 {
    padding-left: 16px;
  }
  .ant-col .inner{
    margin: 8px;
    h3 , h4 {
      line-height: 110%;
      margin: 0px;
    }
    h3 {
      padding-top: 5px;
      font-weight: bold;
      font-stretch:  condensed;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
}
.offer-popup {
  h3 {
    text-align: center;
    font-weight: bold;
  }
}

.clickable {
  cursor: pointer;
}

.floating-input-container {

  .floating {
    background-color: var(--field__background);
    transition: background-color 0.2s ease;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .floating:hover,
  .floating:focus-within {
    background-color: var(--field__background--hover);
  }

  .floating__input {
    padding: 1.8rem 1rem 0.6rem;
    font-size: 1rem;
    border-bottom: 0.1rem solid var(--input__border);
    transition: border-color 0.2s ease;
    caret-color: var(--color__accent);
  }

  .floating:hover .floating__input {
    border-color: var(--input__border--hover);
  }

  .floating__input::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .floating__label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 500;
    pointer-events: none;
  }

  .floating__label::before {
    color: var(--label__color);
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
  }

  .floating__label::after {
    bottom: 1rem;
    content: "";
    height: 0.1rem;
    position: absolute;
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 180ms cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
    opacity: 0;
    left: 0;
    top: 100%;
    margin-top: -0.1rem;
    transform: scale3d(0, 1, 1);
    width: 100%;
    background-color: var(--color__accent);
  }

  .floating__input {
    width: 100%;
    border: 0px;
    border-bottom: 1px solid black;
  }

  .floating__input:focus + .floating__label::after {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  .floating__input:placeholder-shown + .floating__label::before {
    transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
  }

  .floating__label::before,
  .floating__input:focus + .floating__label::before {
    transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
  }

  .floating__input:focus + .floating__label::before {
    color: var(--color__accent);
  }

}

.app-mobile-buttons-container {
  padding-left: 8px;
  padding-right: 8px;
  h2 {padding-left: 16px;}
  .app-link {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    .app-mobile-buttons {
      height: 70px;
      width: auto;

    }
  }
}